import type { Epic, StoreDependencies } from 'behavior/types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type { PaymentInput } from '../types';
import { SUBMIT_PROFORMA_ORDER, submitProformaOrderResultReceived, OrderPaymentAction } from '../actions';
import { submitProformaOrderMutation } from '../queries';
import { mergeMap, startWith, catchError, pluck, exhaustMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { postForm } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { skipIfPreviewWithToast } from 'behavior/preview';

type RedirectAction = {
  type: 'RedirectAction';
  isHtmlMessage: boolean;
  message: string | null;
  url: string;
};

type PostAction = {
  type: 'PostAction';
  url: string;
  values: {
    key: string;
    value: string | null;
  }[];
};

type NextAction = RedirectAction | PostAction;

type SuccessProformaPaymentResult = {
  type: 'SuccessProformaPaymentResult';
  transaction: {
    id: string;
    cancelled: boolean;
    failed: boolean;
  };
  nextAction: NextAction;
};

type FailureProformaPaymentResult = {
  type: 'FailureProformaPaymentActionResult';
};

type ProformaPaymentSubmit = SuccessProformaPaymentResult | FailureProformaPaymentResult;

type SubmitProformaOrderResponse = {
  proformaPayment: {
    type: string;
        submit: ProformaPaymentSubmit;
  };
};

const epic: Epic<OrderPaymentAction> = (action$, state$, deps) => action$.pipe(
    ofType(SUBMIT_PROFORMA_ORDER),
  skipIfPreviewWithToast(state$, deps),
  pluck('payload'),
  exhaustMap(({ orderId, paymentInput, additionalCustomerData, extraPaymentData }) => {
    const { api, logger } = deps;
      return api.graphApi<SubmitProformaOrderResponse>(submitProformaOrderMutation, getSubmitParams(orderId, paymentInput, additionalCustomerData, extraPaymentData), { retries: 0 }).pipe(
          mergeMap(({ proformaPayment }) => {
        if (!proformaPayment)
            return of(submitProformaOrderResultReceived({ error: true }), unsetLoadingIndicator());

          return handleProformaPaymentResult(proformaPayment.submit, deps);
      }),
      catchError(error => {
        logger.error(error);

          return of(submitProformaOrderResultReceived({ error: true }), unsetLoadingIndicator());
      }),
      startWith(setLoadingIndicator()),
    );
  },
  ),
);

export default epic;

function getSubmitParams(orderId: string, paymentInput: PaymentInput, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) {
 const input = { orderId, ...paymentInput };
  if (additionalCustomerData)
    input.additionalCustomerData = { values: additionalCustomerData };

  if (extraPaymentData)
    input.extraPaymentData = { values: extraPaymentData };

  return { input };
}

function handleProformaPaymentResult(result: ProformaPaymentSubmit, dependencies: StoreDependencies) {
  switch (result.type) {
      case 'SuccessProformaPaymentResult':
      return handleSuccessProformaPaymentResult(result, dependencies);
    case 'FailureProformaPaymentActionResult':
      return handleFailureInvoicePaymentResult();
    default:
      dependencies.logger.error('invoice payment result is not handled.', result);
      throw new Error('Unexpected invoice payment result.');
  }
}

function handleSuccessProformaPaymentResult(result: SuccessProformaPaymentResult, dependencies: StoreDependencies) {
  const { transaction, nextAction } = result;

  if (nextAction)
    return handleNextAction(nextAction, dependencies);

  if (transaction.cancelled || transaction.failed) {
    dependencies.logger.error('cancelled & failed transaction statuses are not handled.', result);
    throw new Error('Unexpected transaction status.');
  }

    return of(navigateTo(routesBuilder.forProformaSubmit(transaction.id)));
}

function handleFailureInvoicePaymentResult() {
    return of(submitProformaOrderResultReceived({ error: true }), unsetLoadingIndicator());
}

function handleNextAction(nextAction: NextAction, dependencies: StoreDependencies) {
  switch (nextAction.type) {
    case 'RedirectAction':
      return handleRedirectAction(nextAction);
    case 'PostAction':
      return handlePostAction(nextAction);
    default:
      dependencies.logger.error('next action is not handled.', nextAction);
      throw new Error('Unexpected next action.');
  }
}

function handlePostAction({ type: _type, ...postAction }: PostAction) {
  return of(postForm(postAction));
}

function handleRedirectAction({ message, isHtmlMessage, url }: RedirectAction) {
  message && toasts.info(isHtmlMessage ? renderHTML(message) : message, { autoDismiss: false });
  window.location.href = url;

  return EMPTY;
}
