import type { Epic } from 'behavior/types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import { mergeMap, startWith, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { PROFORMA_METHOD_EXTRA_DATA_REQUESTED, receivePaymentMethodExtraData, OrderPaymentAction } from '../actions';
import { proformaPaymentMethodExtraDataQuery } from '../queries';
import { skipIfPreview } from 'behavior/preview';

type PaymentMethodExtraDataResponse = {
  proformaPayment: {
    paymentMethods: {
      byId: {
        extraPaymentCheckoutStep: ExtraPaymentData;
        additionalCustomerDataStep: AdditionalCustomerData;
      };
    };
  };
};

const epic: Epic<OrderPaymentAction> = (action$, state$, { api }) => action$.pipe(
    ofType(PROFORMA_METHOD_EXTRA_DATA_REQUESTED),
  skipIfPreview(state$),
  pluck('payload'),
    mergeMap(({ id, paymentMethodId }) => api.graphApi<PaymentMethodExtraDataResponse>(proformaPaymentMethodExtraDataQuery, { id, paymentMethodId }).pipe(
    pluck('proformaPayment', 'paymentMethods', 'byId'),
    mergeMap(({ additionalCustomerDataStep, extraPaymentCheckoutStep }) => of(receivePaymentMethodExtraData(paymentMethodId, additionalCustomerDataStep, extraPaymentCheckoutStep), unsetLoadingIndicator())),
    startWith(setLoadingIndicator()),
  )),
);

export default epic;
