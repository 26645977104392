import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { proformaResultPageQuery } from './queries';
import { initSystemPageContent, SystemPageData, SystemPage } from 'behavior/pages/system';
import { Handler } from '../types';
import { RouteName } from 'routes';

const handler: Handler<ProformaResultRouteData, ProformaPaymentResultPage> = (routeData, _state$, { api }) => {
    const { params: { id, result } } = routeData;

    return api.graphApi<ProformaResultPageResponse>(proformaResultPageQuery).pipe(
        map(({
            pages: { proformaPayment: proformaPaymentResultPage },
        }) => {
            return {
                page: {
                    component: PageComponentNames.ProformaPaymentResult as const,
                    id,
                    result,
                    ...proformaPaymentResultPage,
                },
            };
        }),
        initSystemPageContent(),
    );
};

export default handler;

type ProformaResultRouteData = {
    routeName: RouteName.ProformaSubmit;
    params: {
        id?: string;
        result?: boolean;
    };
};

type ProformaResultPageResponse = {
    pages: {
        proformaPayment: SystemPageData;
    };
};

type ProformaPaymentResultPage = SystemPage & {
    component: PageComponentNames.ProformaPaymentResult;
    id?: string;
    result?: boolean;
};