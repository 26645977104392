import type { Document, PaymentStatus } from './types';
import { QuoteStatus, DocumentType } from './constants';
import dateOnly from 'date-only';

export const isDocumentCancelled = (document: Document) =>
  document.status === QuoteStatus.Cancelled || document.status === 'CANCELLED';

export const isDocumentPromotableQuote = (document: Document, documentType: keyof typeof DocumentType) =>
  documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

const fieldPerDocumentType = {
  [DocumentType.Order]: 'orders',
  [DocumentType.Quote]: 'quotes',
  [DocumentType.Invoice]: 'invoices',
  [DocumentType.ReturnOrder]: 'returnOrders',
  [DocumentType.CreditNote]: 'creditNotes',
  [DocumentType.ReturnReceipt]: 'returnReceipts',
  [DocumentType.Shipment]: 'shipments',
} as const;

export function getDocumentField(documentType: DocumentType) {
  return fieldPerDocumentType[documentType];
}

const documentDetailsFieldMap = {
  [DocumentType.Order]: 'orderDetails',
  [DocumentType.Quote]: 'quoteDetails',
  [DocumentType.Invoice]: 'invoiceDetails',
  [DocumentType.ReturnOrder]: 'returnOrderDetails',
  [DocumentType.CreditNote]: 'creditNoteDetails',
  [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
  [DocumentType.Shipment]: 'shipmentDetails',
} as const;

export function getDocumentDetailsField(documentType: keyof typeof documentDetailsFieldMap) {
  return documentDetailsFieldMap[documentType];
}

/* TicketSimple 201595: Document is overdue */
type Invoice = {
  dueDate?: string | null;
  payment: {
    status: PaymentStatus | null;
  };
};

export const canInvoiceBePaid = (invoice: Invoice, allowOverdueInvoicePayment: boolean): boolean => {
  const isOverdue = 'dueDate' in invoice && invoice.dueDate && new Date(invoice.dueDate) < dateOnly.today();
  if (isOverdue && !allowOverdueInvoicePayment)
    return false;

  const paymentStatus = invoice.payment.status;

  return paymentStatus !== 'inprogress' && paymentStatus !== 'paid';
};
/* TicketSimple 201595: Document is overdue */