import type { OrderResult, ProformaResult } from './types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type {
  OrderPaymentAction,
  ReceivePaymentMethodExtraData,
  ReceivePayOrderResultAction,
} from './actions';
import {
    CREATE_PROFORMA_ORDER_RESULT_RECEIVED,
  PAY_ORDER,
  PAY_ORDER_RESULT_RECEIVED,
    PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
    CreateProformaOrderResultReceivedAction,
    SUBMIT_PROFORMA_ORDER_RESULT_RECEIVED,
    SubmitProformaOrderResultReceivedAction,
    SUBMIT_PROFORMA_ORDER,
} from './actions';
import { createReducer } from 'utils/redux';

type OrderPaymentState = {
    payOrderResult?: OrderResult | null;
    submitProformaOrderResult?: ProformaResult | null;
  additionalCustomerData?: AdditionalCustomerData;
    extraPaymentData?: { paymentMethodId: string } & ExtraPaymentData;
    createProformaOrderResult?: OrderResult;
};

export default createReducer<OrderPaymentState, OrderPaymentAction>(null as unknown as OrderPaymentState, {
  [PAY_ORDER_RESULT_RECEIVED]: onPayOrderResultReceived,
    [PAY_ORDER]: onPayOrder,
    [SUBMIT_PROFORMA_ORDER]: onSubmitProformaOrder,
    [PAYMENT_METHOD_EXTRA_DATA_RECEIVED]: onPaymentMethodExtraDataReceived,
    [CREATE_PROFORMA_ORDER_RESULT_RECEIVED]: onCreateProformaOrderResultReceived,
    [SUBMIT_PROFORMA_ORDER_RESULT_RECEIVED]: onSubmitProformaOrderResultReceived,
});

function onCreateProformaOrderResultReceived(state: OrderPaymentState, action: CreateProformaOrderResultReceivedAction) {
    return {
        ...state,
        createProformaOrderResult: action.payload.result,
    };
}

function onSubmitProformaOrderResultReceived(state: OrderPaymentState, action: SubmitProformaOrderResultReceivedAction) {
    return {
        ...state,
        submitProformaOrderResult: action.payload.result,
        isProformaOrderSubmitting: false,
    };
}

function onPayOrderResultReceived(state: OrderPaymentState, action: ReceivePayOrderResultAction) {
  return { ...state, payOrderResult: action.payload.result };
}

function onPayOrder(state: OrderPaymentState) {
  return { ...state, payOrderResult: null };
}

function onPaymentMethodExtraDataReceived(state: OrderPaymentState, action: ReceivePaymentMethodExtraData) {
  const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

  return {
    ...state,
    additionalCustomerData,
    extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
  };
}

function onSubmitProformaOrder(state: OrderPaymentState) {
    return {
        ...state,
        submitProformaOrderResult: null,
        isProformaOrderSubmitting: true,
    };
}
