import type { Epic } from 'behavior/types';
import { CREATE_PROFORMA_ORDER, OrderPaymentAction, createProformaOrderResultReceived } from '../actions';
import { pluck, exhaustMap, mergeMap, startWith, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { createProformaOrderMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

type CreatePerformaOrderResponse = {
    proformaPayment: {
        create: {
            orderId: string;
        };
    };
};

const epic: Epic<OrderPaymentAction> = (action$, _, { api, logger }) => action$.pipe(
    ofType(CREATE_PROFORMA_ORDER),
    pluck('payload'),
    exhaustMap(({ id }) => api.graphApi<CreatePerformaOrderResponse>(createProformaOrderMutation, { id }, { retries: 0 }).pipe(
            mergeMap(({ proformaPayment }) => {
                if (!proformaPayment)
                    return of(createProformaOrderResultReceived({ error: true }), unsetLoadingIndicator());

                return of(navigateTo(routesBuilder.forProformaPayment(proformaPayment.create.orderId)));
            }),
            catchError(error => {
                logger.error(error);

                return of(createProformaOrderResultReceived({ error: true }), unsetLoadingIndicator());
            }),
            startWith(setLoadingIndicator()),
        )),
);

export default epic;
