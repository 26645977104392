import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { proformaPaymentPageQuery } from './queries';
import { initSystemPageContent, SystemPageData, SystemPage } from 'behavior/pages/system';
import { Handler } from '../types';
import { RouteName } from 'routes';

const handler: Handler<ProformaPaymentRouteData, ProformaPaymentPage> = (routeData, _state$, { api }) => {
    const { params: { id } } = routeData;
    
    return api.graphApi<ProformaPaymentPageResponse>(proformaPaymentPageQuery, { id }).pipe(
        map(({
            pages: { proformaPayment: proformaPaymentPage },
            proformaPayment,
        }) => {
            if (!proformaPayment)
                return null;

            const { order, paymentMethods: { list: paymentMethods } } = proformaPayment;

            return {
                page: {
                    component: PageComponentNames.ProformaPayment as const,
                    order,
                    paymentMethods: paymentMethods.map(({ info }) => info),                   
                    ...proformaPaymentPage,
                },
            };
        }),
        initSystemPageContent(),
    );
};

export default handler;

type ProformaPaymentRouteData = {
    routeName: RouteName.ProformaPayment;
    params: {
        id?: string;
    };
};

type ProformaPaymentOrder = {
    id: string;
    currency: {
        id: string;
        cultureName: string;
        decimalDigits: number;
        separator: string;
        symbol: string;
    };
    orderDate: string;
    referenceNo: string | null;
    totalAmount: number | null;
};

type PaymentMethodInfo = {
    description: string | null;
    id: string;
    imageUrl: string | null;
    name: string;
    isDefault: boolean | null;
};

type ProformaPaymentPageResponse = {
    pages: {
        proformaPayment: SystemPageData;
    };
    proformaPayment: {
        order: ProformaPaymentOrder;
        paymentMethods: {
            list: {
                info: PaymentMethodInfo;
            }[];
        };
    };
};

type ProformaPaymentPage = SystemPage & {
    component: PageComponentNames.ProformaPayment;
    order: ProformaPaymentOrder;
    paymentMethods: PaymentMethodInfo[];
};

