import loadable from '@loadable/component';
import { routesBuilder } from 'routes';
import createSystemRenderer from 'components/objects/systemPages/renderer';

const ProformaPayment = loadable(() => import(/*webpackChunkName:"user"*/'./ProformaPayment'));
const ProformaPaymentResult = loadable(() => import(/*webpackChunkName:"user"*/'./ProformaPaymentResult'));

function proformaPaymentContentRenderer(page) {
  return (
    <ProformaPayment
      order={page.order}
      paymentMethods={page.paymentMethods}
      additionalCustomerData={page.additionalCustomerData}
      extraPaymentData={page.extraPaymentData}
      submitProformaOrderResult={page.submitProformaOrderResult}
    />
  );
}

export default function proformaPaymentRenderer(page) {
  const backTo = {
    route: routesBuilder.forOrders(),
    texts: ['ButtonText_BackToOrders', 'MyOrders'],
  };
  return createSystemRenderer({ textKey: 'Payment_Header' }, proformaPaymentContentRenderer, backTo)(page);
}

// eslint-disable-next-line react/no-multi-comp
export const proformaPaymentResultRenderer = _page => (
<ProformaPaymentResult
  id={_page.id}
  result={_page.result}
/>
);