import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type { OrderResult, PaymentInput, ProformaResult  } from './types';

export const PAY_ORDER = 'PAY_ORDER/SUBMIT' as const;
export const payOrder = (transactionId: string, paymentInput: PaymentInput, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) => ({
  type: PAY_ORDER,
  payload: { transactionId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const PAY_ORDER_RESULT_RECEIVED = 'PAY_ORDER/RESULT_RECEIVED' as const;
export const receivePayOrderResult = (result: OrderResult) => ({
  type: PAY_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'ORDER_PAYMENT/PAYMENT_METHOD_EXTRA_DATA/REQUESTED' as const;
export const requestPaymentMethodExtraData = (transactionId: string, paymentMethodId: string) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { transactionId, paymentMethodId },
});

export const PROFORMA_METHOD_EXTRA_DATA_REQUESTED = 'ORDER_PAYMENT/PROFORMA_METHOD_EXTRA_DATA/REQUESTED' as const;
export const requestProformaPaymentMethodExtraData = (id: string, paymentMethodId: string) => ({
    type: PROFORMA_METHOD_EXTRA_DATA_REQUESTED,
    payload: { id, paymentMethodId },
});


export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'ORDER_PAYMENT/ADDITIONAL_CUSTOMER_DATA/RECEIVED' as const;
export const receivePaymentMethodExtraData = (paymentMethodId: string, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});

export const CREATE_PROFORMA_ORDER = 'ORDER_PAYMENT/CREATE_PROFORMA_ORDER' as const;
export const createProformaOrder = (id: string) => ({
    type: CREATE_PROFORMA_ORDER,
    payload: { id },
});

export const SUBMIT_PROFORMA_ORDER = 'ORDER_PAYMENT/SUBMIT_PROFORMA_ORDER' as const;
export const submitProformaOrder = (
    orderId: string,
    paymentInput: PaymentInput,
    additionalCustomerData?: AdditionalCustomerData,
    extraPaymentData?: ExtraPaymentData,) => ({
    type: SUBMIT_PROFORMA_ORDER,
    payload: { orderId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const SUBMIT_PROFORMA_ORDER_RESULT_RECEIVED = 'ORDER_PAYMENT/SUBMIT_PROFORMA_ORDER_RESULT_RECEIVED' as const;
export const submitProformaOrderResultReceived = (result: ProformaResult) => ({
    type: SUBMIT_PROFORMA_ORDER_RESULT_RECEIVED,
    payload: { result },
});

export const CREATE_PROFORMA_ORDER_RESULT_RECEIVED = 'ORDER_PAYMENT/CREATE_PROFORMA_ORDER_RESULT_RECEIVED' as const;
export const createProformaOrderResultReceived = (result: OrderResult) => ({
    type: CREATE_PROFORMA_ORDER_RESULT_RECEIVED,
    payload: { result },
});

export type ReceivePayOrderResultAction = ReturnType<typeof receivePayOrderResult>;
export type ReceivePaymentMethodExtraData = ReturnType<typeof receivePaymentMethodExtraData>;
export type PayOrder = ReturnType<typeof payOrder>;
export type CreatePayOrder = ReturnType<typeof createProformaOrder>;
//export type SubmitProformaOrder = ReturnType<typeof submitProformaOrder>;
export type CreateProformaOrderResultReceivedAction = ReturnType<typeof createProformaOrderResultReceived>;
export type SubmitProformaOrderResultReceivedAction = ReturnType<typeof submitProformaOrderResultReceived>;


export type OrderPaymentAction =
    | CreateProformaOrderResultReceivedAction
    | SubmitProformaOrderResultReceivedAction
| ReturnType<
  | typeof receivePayOrderResult
  | typeof receivePaymentMethodExtraData
  | typeof payOrder
    | typeof requestPaymentMethodExtraData
    | typeof requestProformaPaymentMethodExtraData
    | typeof createProformaOrder
    | typeof submitProformaOrder
>;
